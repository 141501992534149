export const UPDATE_CHATS_STATE = "UPDATE_CHATS_STATE"
export const MAKE_CHATS_SOCKET_CONNECTION = "MAKE_CHATS_SOCKET_CONNECTION"
export const CHATS_SOCKET_DISCONNECT = "CHATS_SOCKET_DISCONNECT"
export const CALL_CHATS_SOCKET_METHOD = "CALL_CHATS_SOCKET_METHOD"
export const EMIT_CHATS_CUSTOM_EVENT = "EMIT_CHATS_CUSTOM_EVENT"
export const JOIN_CHATS_PAGE = "JOIN_CHATS_PAGE"
export const DOWNLOAD_LIVE_CHATLOGS = "DOWNLOAD_LIVE_CHATLOGS"
export const CHAT_LIST_RECEIVED_ON_JOIN = "CHAT_LIST_RECEIVED_ON_JOIN"
export const TICKET_LIST_RECEIVED_ON_JOIN = "TICKET_LIST_RECEIVED_ON_JOIN"
export const CHATS_ADDITION = "CHATS_ADDITION"
export const CHATS_UPDATION = "CHATS_UPDATION"
export const CHATS_DELETION = "CHATS_DELETION"
export const JOIN_CHAT_ROOM = "JOIN_CHAT_ROOM"
export const CHAT_ROOM_JOINED = "CHAT_ROOM_JOINED"
export const CHAT_ITEM_CLICKED = "CHAT_ITEM_CLICKED"
export const TICKET_CHAT_ITEM_CLICKED = "TICKET_CHAT_ITEM_CLICKED"
export const JOIN_TICKET_CHAT_ROOM = "JOIN_TICKET_CHAT_ROOM"
export const TICKET_CHAT_ROOM_JOINED = "TICKET_CHAT_ROOM_JOINED"
export const TICKETS_DELETION = "TICKETS_DELETION"
export const TICKETS_ADDITION = "TICKETS_ADDITION"
export const FILTER_TICKETS = "FILTER_TICKETS"
export const GROUP_CHATS = "GROUP_CHATS"
export const SWITCH_ALREADY_JOINED_TICKET = "SWITCH_ALREADY_JOINED_TICKET"
export const UPDATE_ACTIVE_CHATS_ITEM = "UPDATE_ACTIVE_CHATS_ITEM"
export const UPDATE_ACTIVE_CHATS_PINNED_MESSAGES = "UPDATE_ACTIVE_CHATS_PINNED_MESSAGES"
export const UPDATE_UNREAD_LIVE_MESSAGE_COUNT = "UPDATE_UNREAD_LIVE_MESSAGE_COUNT"
export const EMIT_MESSAGE_SEEN = "EMIT_MESSAGE_SEEN"
export const SWITCH_ALREADY_JOINED_CHAT = "SWITCH_ALREADY_JOINED_CHAT"
export const UNTAGGED_CHATLOG_RECEIVED = "UNTAGGED_CHATLOG_RECEIVED"
export const UPDATE_PROFILE_SECTION = "UPDATE_PROFILE_SECTION"

export const CLOSE_CHAT_INTERFACE = "CLOSE_CHAT_INTERFACE"
export const CLOSE_TICKET_CHAT_INTERFACE = "CLOSE_TICKET_CHAT_INTERFACE"





 

// export const UPDATE_CHAT_PROFILE = "UPDATE_CHAT_PROFILE"
export const UPDATE_CHATS_MESSAGE = "UPDATE_CHAT_MESSAGE"
// export const UPDATE_TYPING_INFO = "UPDATE_TYPING_INFO"

// export const GET_LIVE_CHAT_ITEM = "GET_LIVE_CHAT_ITEM"
export const CHATS_MESSAGE_SEEN = "CHATS_MESSAGE_SEEN"
// export const GROUP_TAGS_LOADED = "GROUP_TAGS_LOADED"
// export const ADD_SELECTED_TAG = "ADD_SELECTED_TAG"
// export const REMOVE_SELECTED_TAG = "REMOVE_SELECTED_TAG"

import { message as displayMessage } from "antd"

import states from "./states"
import { setDataInLocalStorage } from "../../utils/dataUtility"
import { findLastIndex } from "../../utils/common"
import { LOCAL_STORAGE } from "../../constants/common"
import {
  CHAT_STATE,
  MESSAGE_READ_STATUS,
  MESSAGE_SENDER,
  CHAT_LIST_TYPE,
} from "../../constants/chats"
import { MODULE_CONFIG } from "../../config/setup"
import {
  GLOBAL_UPDATE_MESSAGE,
  GLOBAL_UPDATE_ACTIVE_CHATS_ITEM,
} from "../page_details/actiontypes"
import {
  UPDATE_CHATS_STATE,
  CHAT_LIST_RECEIVED_ON_JOIN,
  CHATS_ADDITION,
  CHATS_UPDATION,
  CHATS_DELETION,
  CHAT_ROOM_JOINED,
  UPDATE_ACTIVE_CHATS_ITEM,
  UPDATE_PROFILE_SECTION,
  CLOSE_CHAT_INTERFACE,
  UPDATE_UNREAD_LIVE_MESSAGE_COUNT,
  UPDATE_CHATS_MESSAGE,
  CHATS_MESSAGE_SEEN,
  SWITCH_ALREADY_JOINED_CHAT,
  SWITCH_ALREADY_JOINED_TICKET,
  UNTAGGED_CHATLOG_RECEIVED,
  TICKET_LIST_RECEIVED_ON_JOIN,
  TICKET_CHAT_ROOM_JOINED,
  TICKETS_DELETION,
  TICKETS_ADDITION,
  CLOSE_TICKET_CHAT_INTERFACE,
  FILTER_TICKETS,
  GROUP_CHATS,
  UPDATE_ACTIVE_CHATS_PINNED_MESSAGES,
} from "./actiontypes"

const chats_details = (state = states.chats_details, action) => {
  switch (action.type) {
    case UPDATE_CHATS_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case CHAT_LIST_RECEIVED_ON_JOIN: {
      let active_chats = { ...state.active_chats }
      let selected_active_chat_id = state.selected_active_chat_id
      let selected_active_chat_type = state.selected_active_chat_type
      let chat_list = action.payload
      if(process.env.REACT_APP_PRIORITY_CHATS==="true"){
        const sortedArray = Object.entries(chat_list).sort(([, a], [, b]) => {
          const priorityA = a.priorityValue ? parseInt(a.priorityValue) : Infinity
          const priorityB = b.priorityValue ? parseInt(b.priorityValue) : Infinity
          return priorityA - priorityB
        })
      chat_list= Object.fromEntries(sortedArray)
      }
      if (
        !(selected_active_chat_id && chat_list[selected_active_chat_id]) &&
        (action.key === "untagged_chats"
          ? selected_active_chat_type === CHAT_LIST_TYPE.UNTAGGED_CHATS
          : selected_active_chat_type !== CHAT_LIST_TYPE.UNTAGGED_CHATS)
      ) {
        selected_active_chat_id = null
        selected_active_chat_type = null
        delete active_chats[state.selected_active_chat_id]
      }
      return {
        ...state,
        [action.key]: chat_list,
        selected_active_chat_id,
        selected_active_chat_type,
        active_chats,
      }
    }

    case TICKET_LIST_RECEIVED_ON_JOIN: {
      let active_ticket_chats = { ...state.active_ticket_chats }
      let selected_active_ticket_chat_id = state.selected_active_ticket_chat_id
      return {
        ...state,
        [action.key]: action.payload,
        selected_active_ticket_chat_id,
        active_ticket_chats,
      }
    }

    case GROUP_CHATS: {
      const { live_chats, selected_live_groups } = state

      const filteredChatIds = Object.keys(live_chats).filter(chatId => {
        const live_chat = live_chats[chatId]
        const isMatchingGroup =
        selected_live_groups.includes(live_chat.group)
        return isMatchingGroup
      })

      const filtered_live_chats = filteredChatIds.reduce(
        (acc, chatId) => {
          acc[chatId] = live_chats[chatId]
          return acc
        },
        {}
      )

      return {
        ...state,
        filtered_live_chats,
      }
    }

    case FILTER_TICKETS: {
      const { tickets_chats, selected_group, selected_priority } = state
      const filteredTicketIds = Object.keys(tickets_chats).filter(ticketId => {
        const ticket = tickets_chats[ticketId]
        const isMatchingGroup =
        selected_group.length === 0 || selected_group.includes(ticket.group);
        const isMatchingPriority =
          !selected_priority || ticket.priority === selected_priority

        return isMatchingGroup && isMatchingPriority
      })

      const filtered_ticket_chats = filteredTicketIds.reduce(
        (acc, ticketId) => {
          acc[ticketId] = tickets_chats[ticketId]
          return acc
        },
        {}
      )

      return {
        ...state,
        filtered_ticket_chats,
      }
    }

    case CHATS_ADDITION: {
      let chat_list=null
      let sortedData={}
      if(process.env.REACT_APP_PRIORITY_CHATS==="true"){
        let chat_list= {
          ...state[action.key],
          [action.id]: action.payload,
        }
      const sortedKeys = Object.keys(chat_list).sort((keyA, keyB) => {
        const priorityA = chat_list[keyA]?.priorityValue ? parseInt(chat_list[keyA].priorityValue) : Infinity
        const priorityB = chat_list[keyB]?.priorityValue ? parseInt(chat_list[keyB].priorityValue) : Infinity
        return priorityA - priorityB
      })
      for (const key of sortedKeys) sortedData[key] = chat_list[key]
      }
      return {
        ...state,
        [action.key]: chat_list === null ? {
          ...state[action.key],
          [action.id]: action.payload,
        }: {sortedData},
      }
    }

    case TICKETS_ADDITION: {
      if (action.priority) {
        const newState = {
          ...state,
          [action.key]: {
            ...state[action.key],
            [action.id]: action.payload,
          },
        }
        newState[action.key] = Object.fromEntries(
          Object.entries(newState[action.key]).sort(([, a], [, b]) =>
            a.priority.localeCompare(b.priority)
          )
        )
        return newState
      } else
        return {
          ...state,
          [action.key]: {
            ...state[action.key],
            [action.id]: action.payload,
          },
        }
    }

    case CHATS_UPDATION: {
      let live_chats = { ...state.live_chats }
      let unseen_live_chats_stall_count = {
        ...state.unseen_live_chats_stall_count,
      }
      if (live_chats[action.payload.psid]) {
        if (
          !(
            state.selected_active_chat_id &&
            state.selected_active_chat_id === action.payload.psid
          ) &&
          action.payload.chatState === CHAT_STATE.AGENT_HANDLING &&
          action.payload.agentId === action.admin_id
        ) {
          if (
            action.payload.adminStallCount !==
            live_chats[action.payload.psid].adminStallCount
          ) {
            unseen_live_chats_stall_count[action.payload.psid] = {
              ...unseen_live_chats_stall_count[action.payload.psid],
              admin_stall_count: true,
            }
          }
          if (
            action.payload.customerStallCount !==
            live_chats[action.payload.psid].customerStallCount
          ) {
            unseen_live_chats_stall_count[action.payload.psid] = {
              ...unseen_live_chats_stall_count[action.payload.psid],
              customer_stall_count: true,
            }
          }
          setDataInLocalStorage(
            LOCAL_STORAGE.UNSEEN_LIVE_CHATS_STALL_COUNT,
            unseen_live_chats_stall_count
          )
        }
        live_chats[action.payload.psid] = {
          ...live_chats[action.payload.psid],
          ...action.payload,
        }
        if(process.env.REACT_APP_PRIORITY_CHATS==="true") {   
          const sortedKeys = Object.keys(live_chats).sort((keyA, keyB) => {
            const priorityA = live_chats[keyA]?.priorityValue ? parseInt(live_chats[keyA].priorityValue) : Infinity;
            const priorityB = live_chats[keyB]?.priorityValue ? parseInt(live_chats[keyB].priorityValue) : Infinity;
            return priorityA - priorityB;
          });
          
          const sortedData={}
          for (const key of sortedKeys) 
            sortedData[key] = live_chats[key]
          
          live_chats=sortedData
        }
      } else {
        if(process.env.REACT_APP_PRIORITY_CHATS==="true") {
          live_chats[action.payload.psid] = action.payload      
          const sortedKeys = Object.keys(live_chats).sort((keyA, keyB) => {
            const priorityA = live_chats[keyA]?.priorityValue ? parseInt(live_chats[keyA].priorityValue) : Infinity;
            const priorityB = live_chats[keyB]?.priorityValue ? parseInt(live_chats[keyB].priorityValue) : Infinity;
            return priorityA - priorityB;
          });
          
          const sortedData={}
          for (const key of sortedKeys) 
            sortedData[key] = live_chats[key]
          
          live_chats=sortedData
        }else live_chats[action.payload.psid] = action.payload //TODO: this will create addition of chatitem, which is wrong.
      }
      return {
        ...state,
        live_chats,
        unseen_live_chats_stall_count,
      }
    }

    case CHATS_DELETION: {
      let live_chats = { ...state.live_chats }
      let untagged_chats = { ...state.untagged_chats }
      let active_chats = { ...state.active_chats }
      let unread_live_message_count = { ...state.unread_live_message_count }
      let unseen_live_chats_stall_count = {
        ...state.unseen_live_chats_stall_count,
      }
      let filtered_live_chats = { ...state.filtered_live_chats }

      if (active_chats[action.payload.id]) {
        delete active_chats[action.payload.id]
        if (action.reason) displayMessage.warning(action.reason)
      }

      if (action.payload.type === CHAT_LIST_TYPE.UNTAGGED_CHATS)
        delete untagged_chats[action.payload.id]
      else {
        if (unread_live_message_count[action.payload.id]) {
          delete unread_live_message_count[action.payload.id]
          setDataInLocalStorage(
            LOCAL_STORAGE.LIVE_CHATS_UNREAD_MESSAGE_COUNT,
            unread_live_message_count
          )
        }
        if (unseen_live_chats_stall_count[action.payload.id]) {
          delete unseen_live_chats_stall_count[action.payload.id]
          setDataInLocalStorage(
            LOCAL_STORAGE.UNSEEN_LIVE_CHATS_STALL_COUNT,
            unseen_live_chats_stall_count
          )
        }
        delete live_chats[action.payload.id]
        delete filtered_live_chats[action.payload.id]
      }
      return {
        ...state,
        unread_live_message_count,
        unseen_live_chats_stall_count,
        untagged_chats,
        live_chats,
        filtered_live_chats,
        selected_active_chat_id:
          state.selected_active_chat_id === action.payload.id
            ? null
            : state.selected_active_chat_id,
        selected_active_chat_type:
          state.selected_active_chat_id === action.payload.id
            ? null
            : state.selected_active_chat_type,
        active_chats,
      }
    }

    case TICKETS_DELETION: {
      let tickets_chats = { ...state.tickets_chats }
      let active_ticket_chats = { ...state.active_ticket_chats }
      let filtered_ticket_chats = { ...state.filtered_ticket_chats }

      if (active_ticket_chats[action.payload.id]) {
        delete active_ticket_chats[action.payload.id]
        if (action.reason) displayMessage.warning(action.reason)
      }
      if (tickets_chats[action.payload.id]) {
        delete tickets_chats[action.payload.id]
        if (action.reason) displayMessage.warning(action.reason)
      }
      if (filtered_ticket_chats[action.payload.id]) {
        delete filtered_ticket_chats[action.payload.id]
        if (action.reason) displayMessage.warning(action.reason)
      }

      return {
        ...state,
        tickets_chats,
        active_ticket_chats,
        filtered_ticket_chats,
        selected_active_ticket_chat_id:
          state.selected_active_ticket_chat_id === action.payload.id
            ? null
            : state.selected_active_ticket_chat_id,
      }
    }

    case CLOSE_CHAT_INTERFACE: {
      let active_chats = { ...state.active_chats }
      if (state.selected_active_chat_id)
        delete active_chats[state.selected_active_chat_id]
      return {
        ...state,
        selected_active_chat_id: null,
        selected_active_chat_type: null,
        active_chats,
      }
    }

    case CLOSE_TICKET_CHAT_INTERFACE: {
      let active_ticket_chats = { ...state.active_ticket_chats }
      if (state.selected_active_ticket_chat_id)
        delete active_ticket_chats[state.selected_active_ticket_chat_id]
      return {
        ...state,
        selected_active_ticket_chat_id: null,
        active_ticket_chats,
      }
    }

    case CHAT_ROOM_JOINED: {
      let unread_live_message_count = { ...state.unread_live_message_count }
      let unseen_live_chats_stall_count = {
        ...state.unseen_live_chats_stall_count,
      }
      let active_chats = { ...state.active_chats }
      const active_ids = Object.keys(active_chats)
      if (active_ids.length >= MODULE_CONFIG.CHATS.STORED_LIVE_CHAT_ITEMS_COUNT)
        delete active_chats[active_ids[0]]
      if (unread_live_message_count[action.id]) {
        delete unread_live_message_count[action.id]
        setDataInLocalStorage(
          LOCAL_STORAGE.LIVE_CHATS_UNREAD_MESSAGE_COUNT,
          unread_live_message_count
        )
      }
      if (unseen_live_chats_stall_count[action.id]) {
        delete unseen_live_chats_stall_count[action.id]
        setDataInLocalStorage(
          LOCAL_STORAGE.UNSEEN_LIVE_CHATS_STALL_COUNT,
          unseen_live_chats_stall_count
        )
      }
      return {
        ...state,
        unread_live_message_count,
        unseen_live_chats_stall_count,
        selected_active_chat_id: action.id,
        selected_active_chat_type: null,
        active_chats: {
          ...active_chats,
          [action.id]: {
            messages: action.payload.activeChatSessionLogs
              ? action.payload.activeChatSessionLogs
              : [],
            pinnedMessages: action.payload.pinnedChatSessionLogs || [],
            profile: action.payload.userProfileDetails
              ? action.payload.userProfileDetails
              : {},
            selected_tags: action.payload.selectedTags
              ? action.payload.selectedTags
              : [],
            typing_info: {},
            eventListenTimeStamp: action.payload.eventListenTimeStamp
              ? action.payload.eventListenTimeStamp
              : "",
            session_language: action.payload.session_language
              ? action.payload.session_language
              : null,
          },
        },
      }
    }

    case TICKET_CHAT_ROOM_JOINED: {
      let active_ticket_chats = { ...state.active_ticket_chats }
      return {
        ...state,
        selected_active_ticket_chat_id: action.id,
        active_ticket_chats: {
          ...active_ticket_chats,
          [action.id]: {
            messages: action.payload.ticketChatLogs
              ? action.payload.ticketChatLogs
              : [],
            profile: action.payload.userProfileDetails
              ? action.payload.userProfileDetails
              : {},
          },
        },
      }
    }

    case UNTAGGED_CHATLOG_RECEIVED: {
      let active_chats = { ...state.active_chats }
      const active_ids = Object.keys(active_chats)
      if (active_ids.length >= MODULE_CONFIG.CHATS.STORED_LIVE_CHAT_ITEMS_COUNT)
        delete active_chats[active_ids[0]]
      return {
        ...state,
        selected_active_chat_type: CHAT_LIST_TYPE.UNTAGGED_CHATS,
        selected_active_chat_id: action.id,
        active_chats: {
          ...active_chats,
          [action.id]: {
            messages: action.payload.chatLogs,
            profile: action.payload.userProfileDetails,
            selected_tags: [],
            typing_info: {},
          },
        },
      }
    }

    case SWITCH_ALREADY_JOINED_CHAT: {
      let unread_live_message_count = { ...state.unread_live_message_count }
      let unseen_live_chats_stall_count = {
        ...state.unseen_live_chats_stall_count,
      }
      if (action.payload.type !== CHAT_LIST_TYPE.UNTAGGED_CHATS) {
        if (unread_live_message_count[action.payload.id]) {
          delete unread_live_message_count[action.payload.id]
          setDataInLocalStorage(
            LOCAL_STORAGE.LIVE_CHATS_UNREAD_MESSAGE_COUNT,
            unread_live_message_count
          )
        }
        if (unseen_live_chats_stall_count[action.payload.id]) {
          delete unseen_live_chats_stall_count[action.payload.id]
          setDataInLocalStorage(
            LOCAL_STORAGE.UNSEEN_LIVE_CHATS_STALL_COUNT,
            unseen_live_chats_stall_count
          )
        }
      }
      return {
        ...state,
        unread_live_message_count,
        unseen_live_chats_stall_count,
        selected_active_chat_id: action.payload.id,
        selected_active_chat_type: state.untagged_chats[action.payload.id]
          ? CHAT_LIST_TYPE.UNTAGGED_CHATS
          : null,
      }
    }

    case SWITCH_ALREADY_JOINED_TICKET: {
      return {
        ...state,
        selected_active_ticket_chat_id: action.payload.id,
      }
    }

    case GLOBAL_UPDATE_ACTIVE_CHATS_ITEM:
    case UPDATE_ACTIVE_CHATS_ITEM: {
      if (state.active_chats[action.payload.id]) {
        if (
          action.payload.edit_index !== undefined &&
          action.payload.edit_index !== -1
        ) {
          const updatedKey = [
            ...state.active_chats[action.payload.id][action.payload.key],
          ]
          updatedKey[action.payload.edit_index] = action.payload.value
          return {
            ...state,
            active_chats: {
              ...state.active_chats,
              [action.payload.id]: {
                ...state.active_chats[action.payload.id],
                [action.payload.key]: updatedKey,
                ...(action.payload.dependant_changed_data
                  ? action.payload.dependant_changed_data
                  : {}),
              },
            },
          }
        } else
          return {
            ...state,
            active_chats: {
              ...state.active_chats,
              [action.payload.id]: {
                ...state.active_chats[action.payload.id],
                [action.payload.key]: action.payload.replace
                  ? action.payload.value
                  : action.payload.is_array
                  ? [
                      ...state.active_chats[action.payload.id][
                        action.payload.key
                      ],
                      action.payload.value,
                    ]
                  : {
                      ...state.active_chats[action.payload.id][
                        action.payload.key
                      ],
                      ...action.payload.value,
                    },
                ...(action.payload.dependant_changed_data
                  ? action.payload.dependant_changed_data
                  : {}),
              },
            },
          }
      } else return state
    }

    case UPDATE_ACTIVE_CHATS_PINNED_MESSAGES: {
      if (state.active_chats[action.payload.id]) {
        let updatedPinnedMessages =
          state.active_chats?.[action.payload.id]?.[action.payload.key] || []

        if (action.payload?.isPinned) {
          const isAlreadyPinned = updatedPinnedMessages?.some(
            msg => msg?.chatlogId === action.payload.value?.chatlogId
          )
          if (!isAlreadyPinned) {
            updatedPinnedMessages = [
              action.payload.value,
              ...updatedPinnedMessages,
            ]
          }
        } else {
          updatedPinnedMessages = updatedPinnedMessages.filter(
            msg => msg?.chatlogId !== action.payload.value?.chatlogId
          )
        }

        return {
          ...state,
          active_chats: {
            ...state.active_chats,
            [action.payload.id]: {
              ...state.active_chats[action.payload.id],
              [action.payload.key]: updatedPinnedMessages,
              ...(action.payload.dependant_changed_data
                ? action.payload.dependant_changed_data
                : {}),
            },
          },
        }
      } else return state
    }

    case UPDATE_PROFILE_SECTION: {
      const targetSection = state.active_chats[
        action.payload.psid
      ]?.profile?.sections?.find(
        section => section.sectionTitle === "Usage Profile"
      )

      if (state.active_chats[action.payload.psid] && targetSection) {
        return {
          ...state,
          active_chats: {
            ...state.active_chats,
            [action.payload.psid]: {
              ...state.active_chats[action.payload.psid],
              profile: {
                ...state.active_chats[action.payload.psid]?.profile,
                sections: state.active_chats[
                  action.payload.psid
                ]?.profile?.sections.map(section =>
                  section.sectionTitle === "Usage Profile"
                    ? {
                        ...section,
                        data: section.data.some(
                          dataItem => dataItem.subTitle === "Current Url"
                        )
                          ? section.data.map(dataItem =>
                              dataItem.subTitle === "Current Url"
                                ? {
                                    ...dataItem,
                                    value: action.payload.clientUrl,
                                  }
                                : dataItem
                            )
                          : [
                              ...section.data,
                              {
                                subTitle: "Current Url",
                                value: action.payload.clientUrl,
                                type: "url",
                              },
                            ],
                      }
                    : section
                ),
              },
            },
          },
        }
      } else {
        return state
      }
    }

    case UPDATE_UNREAD_LIVE_MESSAGE_COUNT: {
      let unread_live_message_count = { ...state.unread_live_message_count }
      if (unread_live_message_count[action.payload])
        unread_live_message_count[action.payload]++
      else unread_live_message_count[action.payload] = 1
      setDataInLocalStorage(
        LOCAL_STORAGE.LIVE_CHATS_UNREAD_MESSAGE_COUNT,
        unread_live_message_count
      )
      return {
        ...state,
        unread_live_message_count,
      }
    }

    case GLOBAL_UPDATE_MESSAGE:
    case UPDATE_CHATS_MESSAGE: {
      if (
        action.payload.psid &&
        action.payload.chatlogId &&
        (action.payload.changedValue || action.payload.message) &&
        state.active_chats[action.payload.psid]
      ) {
        let index = -1
        index = findLastIndex(
          state.active_chats[action.payload.psid].messages,
          message => message.chatlogId === action.payload.chatlogId
        )
        if (index >= 0) {
          return {
            ...state,
            active_chats: {
              ...state.active_chats,
              [action.payload.psid]: {
                ...state.active_chats[action.payload.psid],
                messages: [
                  ...state.active_chats[action.payload.psid].messages.slice(
                    0,
                    index
                  ),
                  action.payload.changedValue
                    ? {
                        ...state.active_chats[action.payload.psid].messages[
                          index
                        ],
                        ...action.payload.changedValue,
                      }
                    : action.payload.message,
                  ...state.active_chats[action.payload.psid].messages.slice(
                    index + 1
                  ),
                ],
              },
            },
          }
        } else return state
      } else return state
    }

    case CHATS_MESSAGE_SEEN: {
      if (state.active_chats[action.payload]) {
        let messages = [...state.active_chats[action.payload].messages]
        for (let i = 0; i < messages.length; i++) {
          if (
            messages[i].readStatus === MESSAGE_READ_STATUS.DELIVERED &&
            messages[i].sender !== MESSAGE_SENDER.CUSTOMER
          ) {
            messages[i] = {
              ...messages[i],
              readStatus: MESSAGE_READ_STATUS.SEEN,
            }
          }
        }
        return {
          ...state,
          active_chats: {
            ...state.active_chats,
            [action.payload]: {
              ...state.active_chats[action.payload],
              messages,
            },
          },
        }
      } else return state
    }

    default:
      return state
  }
}

export default chats_details
